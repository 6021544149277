import styles from './DownloadApp.module.scss';

import { DOWNLOAD_APP } from '@/utilities/constants/DownloadApp';
import { useLocale } from '@/utilities/contexts/Locale';
import Button from '@/components/atoms/Button';
import Product from '@/components/molecules/Product';
import typeStore from '@/utilities/types/Store';
import useBranchLink from '@/utilities/hooks/useBranchLink';

interface DownloadAppProps {
  type: 'button' | 'app-badges';
  store?: typeStore;
  location?: string;
}

const DownloadApp = (props: DownloadAppProps) => {
  const { type, store, location } = props;

  const locale: string = useLocale();

  const { withAmpDev } = useBranchLink();

  return (
    <div className={styles.root}>
      {type === "button" ? (
        <div className={styles.button}>
          <Button
            className={styles.button}
            dataAnchorValues={location}
            dataAnchor="get-the-app"
            external={true}
            size="medium"
            style="primary"
            aria={DOWNLOAD_APP.button.title.alt[locale]}
            href={
              store && store.override && store.getApp
                ? withAmpDev(store.getApp)
                : withAmpDev(DOWNLOAD_APP.button.href[locale])
            }
          >
            {DOWNLOAD_APP.button.title.download[locale]}
          </Button>
        </div>
      ) : (
        <div className={styles.appBadges}>
          <Product logo={false} product="safetyculture"  location={location}/>
        </div>
      )}
    </div>
  );
};

export default DownloadApp;

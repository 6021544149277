import styles from './Layout.module.scss';

import { useBanners } from '@/utilities/hooks/useBanners';
import { useWebsite } from '@/utilities/contexts/Website';
import Breadcrumbs from '@/components/molecules/Breadcrumbs';
import cn from 'classnames';
import Footer from '@/components/organisms/Footer';
import GTM from '@/components/tracking/GTM';
import Header from '@/components/organisms/Header';
import Main from '@/components/organisms/Main';
import Meta from '@/modules/Meta';
import Pagination from '@/components/molecules/Pagination';
import Product from '@/utilities/contexts/Product';
import Status from '@/components/molecules/Status';
import typeBreadcrumbs from '@/utilities/types/Breadcrumbs';
import typeCustomiseLayout from '@/utilities/types/LayoutCustomise';
import typeMenus from '@/utilities/types/Menus';
import typePagination from '@/utilities/types/Pagination';
import typeProduct from '@/utilities/types/Product';
import typeSeo from '@/utilities/types/Seo';
import typeStatus from '@/utilities/types/Status';
import typeStore from '@/utilities/types/Store';
import WEBSITE from '@/utilities/constants/Website';
import BannerDetails from '@/utilities/types/Banners';

interface LayoutProps {
  breadcrumbs?: typeBreadcrumbs[];
  children: React.ReactNode;
  className?: string;
  menus: typeMenus;
  pagination?: typePagination;
  product?: typeProduct['override'];
  seo: typeSeo;
  status?: typeStatus['status'];
  store?: typeStore;
  customise?: typeCustomiseLayout;
  notification?: {
    critical: BannerDetails;
    nonCritical: BannerDetails;
  };
}

const Layout = (props: LayoutProps) => {
  const {
    breadcrumbs,
    children,
    className,
    menus,
    pagination,
    product,
    seo,
    status,
    store,
    customise,
  } = props;

  const website: string = useWebsite();

  const banners = useBanners();

  return (
    <>
      {WEBSITE[website].gtm && <GTM.Noscript />}
      <Product product={product}>
        <div className={cn(className, styles.root)}>
          <Meta seo={seo} />
          <Header
            banners={banners}
            menus={{
              header: menus.header,
            }}
            customise={{
              downloadApp: customise?.header.downloadApp,
              languageToggle: customise?.header.languageToggle,
              notification: customise?.header.notification, // ... keep it for feature notification functionality
              navigationStatus: customise?.header.navigationStatus,
              loginAndSignUpButtonsStatus:
                customise?.header.loginAndSignUpButtonsStatus,
              additionalLogo: {
                logo: {
                  altText: customise?.header?.additionalLogo
                    ? customise?.header?.additionalLogo?.logo?.altText
                    : null,
                  guid: customise?.header?.additionalLogo
                    ? customise?.header.additionalLogo?.logo.guid
                    : null,
                },
              },
            }}
            translations={seo.translations?.filter(
              (translation) => !Object.values(translation).includes(null),
            )}
            store={store}
          />
          <Main>
            {breadcrumbs && customise?.breadcrumbsOn && (
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            )}
            {children}
            {pagination && <Pagination pagination={pagination} />}
          </Main>
          <Footer
            menus={{
              footer: menus.footer,
              legal: menus.legal,
            }}
            customise={{
              mainMenu: customise?.footer.mainMenu,
              legalMenu: customise?.footer.legalMenu,
            }}
          />
          {status && <Status status={status} />}
        </div>
      </Product>
    </>
  );
};

export default Layout;
import styles from './Header.module.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { HEADER } from '@/utilities/constants/Header';
import { useLocale } from '@/utilities/contexts/Locale';
import { useRouter } from 'next/router';
import { useWebsite } from '@/utilities/contexts/Website';
import { WEBSITE } from '@/utilities/constants/Website';
import ArrowOpenSquareOutright from '@/icons/maggie/ArrowOpenSquareOutright';
import ArrowRight from '@/icons/maggie/ArrowRight';
import Asset from '@/icons/maggie/Asset';
import Button from '@/components/atoms/Button';
import Checklist from '@/icons/maggie/Checklist';
import ChevronDown from '@/icons/maggie/ChevronDown';
import cn from 'classnames';
import Column from '@/components/atoms/Column';
import Container from '@/components/atoms/Container';
import DOMPurify from 'isomorphic-dompurify';
import HeaderCustomiseProps from '@/utilities/types/HeaderCustomise';
import Image from 'next/image';
import Puzzle from '@/icons/maggie/Puzzle';
import Row from '@/components/atoms/Row';
import Signal from '@/icons/maggie/Signal';
import Training from '@/icons/maggie/Training';
import typeMenus, { ChildItems } from '@/utilities/types/Menus';
import Typography from '@/components/atoms/Typography';
import GetIcon from '@/utilities/helpers/GetIcon';
import MobileToggle from '@/components/atoms/MobileToggle';
import DownloadApp from '@/components/molecules/DownloadApp';
import typeStore from '@/utilities/types/Store';

import dynamic from 'next/dynamic';
import { Banner } from '@/utilities/types/Banners';
const CriticalBanner = dynamic(() => import('@/components/molecules/CriticalBanner'));
const NonCriticalBanner = dynamic(() => import('@/components/molecules/NonCriticalBanner'));

interface BookDemoProps {
  locale: string;
}

const BookDemo = (props: BookDemoProps) => {
  const { locale } = props;

  return (
    <Button
      href={HEADER.demo.button.href[locale]}
      after={<ArrowRight width={16} height={16} />}
      className={styles.demoButton}
    >
      {HEADER.demo.button.title[locale]}
    </Button>
  );
};

interface SubMenuProps {
  locale: string;
  childItems: ChildItems;
}

const ProductSubMenu = (props: SubMenuProps) => {
  const { locale, childItems } = props;

  const { push } = useRouter();

  const handleMenuClick = (event: any, link: string) => {
    event.target.tagName !== 'A' ? push(link) : '';
  };

  return (
    <div className={styles.submenu}>
      <Container>
        <Row xs={0} lg={2}>
          <Column xs={12} lg={6}>
            <div
              className={cn(styles.items)}
              onClick={(event) =>
                handleMenuClick(event, HEADER.operations.href[locale])
              }
            >
              <Typography tag="span" xs={3}>
                <Button href={HEADER.operations.href[locale]}>
                  {HEADER.operations.title[locale]}
                </Button>
                <ArrowRight />
              </Typography>
              <div className={styles.desktop}>
                <Typography tag="p" xs={2}>
                  {HEADER.operations.description[locale]}
                </Typography>
                <div className={styles.workplaceContainer}>
                  <Row justify={'center'} align={'middle'}>
                    <Column xs={6}>
                      <ul className={styles.platformMenu}>
                        <li key={'inspections'}>
                          <Button
                            before={<Checklist width={16} height={16} />}
                            href={HEADER.operations.inspections.href[locale]}
                            icon={true}
                            size="small"
                            style="tertiary"
                          >
                            {HEADER.operations.inspections.title[locale]}
                          </Button>
                        </li>
                        <li key={'assets'}>
                          <Button
                            before={<Asset width={16} height={16} />}
                            href={HEADER.operations.assets.href[locale]}
                            icon={true}
                            size="small"
                            style="tertiary"
                          >
                            {HEADER.operations.assets.title[locale]}
                          </Button>
                        </li>
                        <li key={'training'}>
                          <Button
                            before={<Training width={16} height={16} />}
                            href={HEADER.operations.training.href[locale]}
                            icon={true}
                            size="small"
                            style="tertiary"
                          >
                            {HEADER.operations.training.title[locale]}
                          </Button>
                        </li>
                        <li key={'sensors'}>
                          <Button
                            before={<Signal width={16} height={16} />}
                            href={HEADER.operations.sensors.href[locale]}
                            icon={true}
                            size="small"
                            style="tertiary"
                          >
                            {HEADER.operations.sensors.title[locale]}
                          </Button>
                        </li>
                        <li key={'integrations'}>
                          <Button
                            before={<Puzzle width={16} height={16} />}
                            href={HEADER.operations.integrations.href[locale]}
                            icon={true}
                            size="small"
                            style="tertiary"
                          >
                            {HEADER.operations.integrations.title[locale]}
                          </Button>
                        </li>
                      </ul>
                    </Column>
                    <Column xs={6}>
                      <Image
                        src="/images/menu/operations-platform-updated.png"
                        alt={HEADER.operations.image.alt[locale]}
                        width={278}
                        height={187}
                      />
                    </Column>
                  </Row>
                </div>
              </div>
            </div>
          </Column>
          <Column xs={12} lg={6} className={styles.gridColumn}>
            <Row xs={0} lg={0} align={'stretch'} grow={1}>
              {childItems.nodes.map((child) => (
                <Column
                  key={child.id}
                  xs={12}
                  lg={6}
                  className={styles.platformBlockColumn}
                >
                  <div
                    className={cn(styles.items, styles.platformBlock)}
                    onClick={() => push(child.url)}
                  >
                    <Typography tag="span" xs={3}>
                      <Button href={child.url}>{child.label}</Button>
                      <ArrowRight />
                    </Typography>
                    <div className={styles.desktop}>
                      {child.description && (
                        <Typography tag="p" xs={2}>
                          {child.description}
                        </Typography>
                      )}
                      {child.childItems.nodes && (
                        <ul>
                          {child.childItems.nodes.map((link) => (
                            <li key={link.id}>
                              <Button
                                external={link.target ? true : false}
                                href={link.url}
                              >
                                {link.label}
                              </Button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </Column>
              ))}
            </Row>
          </Column>
        </Row>
        <Row>
          <BookDemo locale={props.locale} />
        </Row>
      </Container>
    </div>
  );
};

const GenericSubMenu = (props: SubMenuProps) => {
  const { childItems } = props;

  const columnSize = Math.max(12 / childItems.nodes.length, 3);

  const { push } = useRouter();

  const isPlatformBlock = (url: string) => {
    return url !== '#';
  };

  return (
    <div className={cn(styles.submenu, styles.mobileDividers)}>
      <Container>
        <Row xs={0} lg={2}>
          {childItems.nodes.map((child) => (
            <Column key={child.id} xs={12} lg={columnSize}>
              <div
                onClick={
                  isPlatformBlock(child.url) ? () => push(child.url) : undefined
                }
                className={cn(
                  styles.items,
                  isPlatformBlock(child.url) ? styles.platformBlock : undefined,
                )}
              >
                <Typography weight={'bold'} tag="span" xs={3}>
                  {isPlatformBlock(child.url) ? (
                    <>
                      <Button
                        className={styles.iconButton}
                        href={child.url}
                        before={
                          child.cssClasses &&
                          child.cssClasses[0] && (
                            <GetIcon name={child.cssClasses[0]} size={21} />
                          )
                        }
                      >
                        {child.label}
                      </Button>
                      <ArrowRight />
                    </>
                  ) : (
                    child.label
                  )}
                </Typography>
                {child.description && (
                  <Typography className={styles.desktop} tag="p" xs={2}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(child.description),
                      }}
                    />
                  </Typography>
                )}
                {child.childItems.nodes && (
                  <ul>
                    {child.childItems.nodes.map((link) => (
                      <li key={link.id}>
                        <Button
                          external={link.target ? true : false}
                          href={link.url}
                        >
                          {link.label}
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Column>
          ))}
        </Row>
        <Row>
          <BookDemo locale={props.locale} />
        </Row>
      </Container>
    </div>
  );
};

const DropDownMenu = (props: SubMenuProps) => {
  const { childItems } = props;

  return (
    <div className={cn(styles.dropdown, styles.mobileDividers)}>
      <Container>
        <ul>
          {childItems &&
            childItems.nodes.map((child) => (
              <li key={child.id}>
                <Button external={child.target ? true : false} href={child.url}>
                  {child.label}
                </Button>
              </li>
            ))}
        </ul>
      </Container>
    </div>
  );
};

export interface HeaderProps {
  menus?: typeMenus;
  translations?: object[];
  customise: HeaderCustomiseProps;
  banners?: Banner[];
  store?: typeStore;
}

const Header = (props: HeaderProps) => {
  const { menus, translations, customise, banners, store } = props;

  const website: string = useWebsite();

  const locale: string = useLocale();

  const override: string[] = ['de', 'es', 'fr', 'nl', 'pt-br'];

  const { asPath } = useRouter();

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [isLanguages, setIsLanguages] = useState<boolean>(false);

  const [isHover, setIsHover] = useState<boolean>(false);

  const [isSubmenu, setIsSubmenu] = useState<string | null>(null);

  const showLanguageSwitcher: boolean =
    customise.languageToggle && WEBSITE[website].locales.toggle;

  const otherLocales =
    translations
      ?.map((obj) => {
        const [key, value] = Object.entries(obj)[0];
        return {
          locale: key,
          uri: value,
        };
      })
      .filter(({ locale: otherLocale, uri }) => {
        return (
          WEBSITE[website].locales.languages.includes(otherLocale) &&
          otherLocale !== locale &&
          uri !== ''
        );
      })
      .sort((a, b) => a.locale.localeCompare(b.locale)) ?? [];

  const handleMobile = () => {
    setIsMobile(!isMobile);
  };

  const handleLanguages = () => {
    setIsLanguages(!isLanguages);
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseOut = () => {
    setIsHover(false);
  };

  const handleSubmenu = (key: string) => {
    isSubmenu === key ? setIsSubmenu(null) : setIsSubmenu(key);
  };

  useEffect(() => {
    setIsHover(false);
    setIsLanguages(false);
    setIsMobile(false);
  }, [asPath, locale]);
 
  const criticalBanner = banners && banners.find((banner) => banner.id === 'critical');
  const nonCriticalBanner = banners && banners.find((banner) => banner.id === 'non-critical');
  
  return (
    <Fragment>
      {criticalBanner && criticalBanner.data.status &&
        <CriticalBanner content={criticalBanner.data} website={website}/>
      }
      {nonCriticalBanner && nonCriticalBanner.data.status &&
        <NonCriticalBanner content={nonCriticalBanner.data} location="page" website={website} />
      }
      <header
        id={'main-header'}
        // @ts-ignore
        className={cn(
          !customise.navigationStatus && styles.navigationOff,
          isHover && styles['is-hover'],
          isMobile && styles['is-mobile'],
          asPath.includes('partner') && !asPath.includes('partnerships') && styles.partnerPage,
          override.includes(locale) && styles.override,
          styles.root,
        )}
      >
        <div className={styles.container}>
          {customise.navigationStatus ? (
            <Button className={styles.logo} href="/">
              <Image
                alt={WEBSITE[website].logo.alt}
                height={WEBSITE[website].logo.height}
                priority
                src={`/logo/${WEBSITE[website].logo.image}`}
                width={WEBSITE[website].logo.width}
              />
            </Button>
          ) : (
            <Image
              alt={WEBSITE[website].logo.alt}
              height={WEBSITE[website].logo.height}
              priority
              src={`/logo/${WEBSITE[website].logo.image}`}
              width={WEBSITE[website].logo.width}
            />
          )}
          {customise.additionalLogo?.logo?.guid && (
            <div className={styles.additionalLogo}>
              <Typography tag="span" xs={3} family="noto-sans">
                &
              </Typography>
              <Image
                alt={
                  customise.additionalLogo?.logo?.altText
                    ? customise.additionalLogo.logo.altText
                    : 'SafetyCulture Partner'
                }
                height={WEBSITE[website].logo.height}
                priority
                src={customise.additionalLogo?.logo.guid}
                width={WEBSITE[website].logo.width}
              />
            </div>
          )}

          {customise.downloadApp && (
            <DownloadApp type="button" store={store} location="header"/>
          )}

          {nonCriticalBanner && nonCriticalBanner.data.status &&
            <NonCriticalBanner content={nonCriticalBanner.data} location="header" website={website} />
          }
          
          {customise.navigationStatus && (
            <MobileToggle isMobile={isMobile} onClick={handleMobile} />
          )}

          <div className={styles.menu}>
            {menus && menus.header && customise.navigationStatus && (
              <ul>
                {menus.header.map((parent, indexMenu) => {
                  if (!parent.parentId) {
                    const submenu: string = parent.childItems.nodes[0]?.id;
                    return (
                      <li
                        key={parent.id}
                        className={cn(
                          parent.cssClasses.includes('dropdown') &&
                            styles.relative,
                          isSubmenu === parent.id
                            ? styles['is-active']
                            : undefined,
                        )}
                        onMouseEnter={
                          submenu ||
                          (indexMenu === 0 && website === 'safetyculture')
                            ? handleMouseEnter
                            : undefined
                        }
                        onMouseLeave={
                          submenu ||
                          (indexMenu === 0 && website === 'safetyculture')
                            ? handleMouseOut
                            : undefined
                        }
                      >
                        <Button
                          after={
                            submenu ||
                            (indexMenu === 0 && website === 'safetyculture') ? (
                              <ChevronDown width={16} height={16} />
                            ) : undefined
                          }
                          external={parent.target ? true : false}
                          href={submenu ? undefined : parent.url}
                          onClick={
                            submenu ||
                            (indexMenu === 0 && website === 'safetyculture')
                              ? () => handleSubmenu(parent.id)
                              : undefined
                          }
                          type={submenu ? 'button' : undefined}
                        >
                          {parent.label}
                        </Button>
                        {parent.cssClasses.includes('dropdown') ? (
                          <DropDownMenu
                            locale={locale}
                            childItems={parent.childItems}
                          />
                        ) : indexMenu === 0 && website === 'safetyculture' ? (
                          <ProductSubMenu
                            locale={locale}
                            childItems={parent.childItems}
                          />
                        ) : (
                          <GenericSubMenu
                            locale={locale}
                            childItems={parent.childItems}
                          />
                        )}
                      </li>
                    );
                  }
                })}
              </ul>
            )}
            {showLanguageSwitcher && otherLocales.length > 0 && (
              <div
                className={cn(
                  isLanguages && styles['is-active'],
                  styles.languages,
                )}
              >
                <Button
                  after={<ChevronDown width={16} height={16} />}
                  before={<GetIcon name={locale} size={16} />}
                  onClick={handleLanguages}
                  size="large"
                  style="tertiary"
                  type="button"
                >
                  <span>{WEBSITE[website].locales.label[locale]}</span>
                  {override.includes(locale) && <span>{locale}</span>}
                </Button>
                <ul>
                  {otherLocales.map(({ locale: otherLocale, uri }) => {
                    return (
                      <li key={otherLocale}>
                        <Button href={uri} locale={otherLocale}>
                          {WEBSITE[website].locales.label[otherLocale]}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <DownloadApp type="app-badges" store={store} location="header"/>
          </div>
          {customise.loginAndSignUpButtonsStatus && (
            <ul className={styles.members}>
              {customise.navigationStatus &&
                WEBSITE[website].cta.login &&
                WEBSITE[website].cta.login.link[locale] && (
                  <li>
                    <Button
                      before={
                        <ArrowOpenSquareOutright width={16} height={16} />
                      }
                      external={WEBSITE[website].cta.login.external[locale]}
                      href={WEBSITE[website].cta.login.link[locale]}
                      size="medium"
                      style="secondary"
                      dataAnchor="log-in-button"
                    >
                      {WEBSITE[website].cta.login.label[locale]}
                    </Button>
                  </li>
                )}
              {WEBSITE[website].cta.signup &&
                WEBSITE[website].cta.signup.link[locale] && (
                  <li>
                    <Button
                      external={WEBSITE[website].cta.signup.external[locale]}
                      href={WEBSITE[website].cta.signup.link[locale]}
                      size="medium"
                      style="primary"
                      dataAnchor="sign-up-button"
                    >
                      {WEBSITE[website].cta.signup.label[locale]}
                    </Button>
                  </li>
                )}
            </ul>
          )}
        </div>
      </header>
    </Fragment>
  );
};

export default Header;

export const DOWNLOAD_APP: any = {
  button: {
    href: {
      au: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      de: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      en: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      es: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      fr: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      nl: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      'pt-br': 'http://sfty.io/q4Af/FvjJ9Q4pMx',
      us: 'http://sfty.io/q4Af/FvjJ9Q4pMx',
    },
    title: {
      alt: {
        au: 'SafetyCulture (iAuditor)',
        de: 'SafetyCulture (iAuditor)',
        en: 'SafetyCulture (iAuditor)',
        es: 'SafetyCulture (iAuditor)',
        fr: 'SafetyCulture (iAuditor)',
        nl: 'SafetyCulture (iAuditor)',
        'pt-br': 'SafetyCulture (iAuditor)',
        us: 'SafetyCulture (iAuditor)',
      },
      download: {
        au: 'Get the app',
        de: 'App holen',
        en: 'Get the app',
        es: 'Bajar aplicación',
        fr: "Obtenir l'application",
        nl: 'Download App',
        'pt-br': 'Obter aplicativo',
        us: 'Get the app',
      },
    },
  }
};
